import React from 'react';
import PropTypes from 'prop-types';

const FilterList = ({ heading, isDesktop, children }) => {
  if (isDesktop) {
    return children;
  }
  return (
    <>
      {heading && <h3 className="drv-filter-list__heading drv-mt-4 drv-mb-4">{heading}</h3>}

      <ul className="drv-block drv-mb-4">{children}</ul>
    </>
  );
};

FilterList.propTypes = {
  heading: PropTypes.string,
  isDesktop: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

FilterList.defaultProps = {
  heading: '',
};

export default FilterList;
