import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

import FilterDrawerTrigger from './FilterDrawerTrigger';

const FilterDrawerListItem = ({
  title,
  count,
  disabled,
  isDesktop,
  withoutButton,
  children,
  hasSelectedOptions,
  noWrap,
}) => {
  const dropdownRef = useRef();
  const updatedRef = useRef();
  const { totalCounts, theme, selectedDropdown, isVans, controller, filtersUpdated } = useSelector(
    (state) => state.filterControls,
  );
  const dispatch = useDispatch();

  const submitForm = (immediate = false) => {
    const form = document.querySelector(`#${controller}-filters`);
    setTimeout(
      () => {
        if (updatedRef.current) {
          form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
          dispatch({ type: 'SET_FILTERS_UPDATED', payload: false });
          if (immediate) {
            dispatch({ type: 'SET_SELECTED_DROPDOWN', payload: '' });
          }
        }
      },
      immediate ? 0 : 1,
    );
  };

  const handleOutsideClicks = (event) => {
    if (window.innerWidth <= 1200 || selectedDropdown !== title) {
      return;
    }
    const dropdown = dropdownRef.current;
    if (!dropdown) return;

    const isClickInside = dropdown.contains(event.target);
    if (isClickInside) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    } else {
      dispatch({ type: 'SET_SELECTED_DROPDOWN', payload: '' });
      submitForm();
    }
  };

  useEffect(() => {
    if (isDesktop) {
      document.removeEventListener('mousedown', handleOutsideClicks, true);
      document.addEventListener('mousedown', handleOutsideClicks, true);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClicks, true);
      };
    }
  }, [selectedDropdown === title, title]);

  const handleClick = () => {
    if (selectedDropdown === title) {
      submitForm();
    }
    dispatch({ type: 'SET_SELECTED_DROPDOWN', payload: title });
  };

  const handleButton = () => {
    dispatch({ type: 'SET_SELECTED_DROPDOWN', payload: '' });
    submitForm();
  };

  useEffect(() => {
    updatedRef.current = filtersUpdated;
  }, [filtersUpdated]);

  return (
    <li
      className={classnames('drv-filter-drawer-list-item', {
        'drv-filter-drawer-list-item--closed': selectedDropdown !== title,
        'drv-filter-drawer-list-item--open': selectedDropdown === title,
        'drv-filter-drawer-list-item--disabled': disabled,
        'drv-filter-drawer-list-item--dropdown': isDesktop,
        'drv-filter-drawer-list-item--selected': isDesktop && hasSelectedOptions,
        [`drv-filter-drawer-list-item--${theme}`]: theme,
      })}
      ref={dropdownRef}
    >
      <FilterDrawerTrigger
        title={title}
        handleClick={handleClick}
        open={selectedDropdown === title}
        count={count}
        disabled={disabled}
        isDesktop={isDesktop}
      />

      <div
        className={classnames('drv-filter-drawer-list-options-container drv-mb-1', {
          'drv-filter-drawer-list-options-container--open': selectedDropdown === title,
          'drv-filter-drawer-list-options-container--nowrap': noWrap,
        })}
      >
        <div
          className={classnames('drv-filter-drawer-list-options', {
            'pb-2': withoutButton,
          })}
          onClick={() => {
            if (withoutButton) submitForm(true);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              submitForm(true);
            }
          }}
        >
          <ul>{children}</ul>
        </div>
        {isDesktop && !withoutButton && (
          <div className="drv-filter-drawer-list-submit">
            <button
              className="drv-button drv-button--blue"
              type="button"
              data-action="click->ga-filters#push"
              data-ga-filters-event-action="applied_filter_category_desktop"
              data-ga-filters-filter-name={title.toLowerCase().replaceAll(' ', '_')}
              onClick={handleButton}
            >
              View {totalCounts.count && totalCounts.count.toLocaleString('en-GB')} {isVans ? 'vans' : 'cars'}
            </button>
          </div>
        )}
      </div>
    </li>
  );
};

FilterDrawerListItem.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  isDesktop: PropTypes.bool.isRequired,
  withoutButton: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hasSelectedOptions: PropTypes.bool,
  noWrap: PropTypes.bool,
};

FilterDrawerListItem.defaultProps = {
  disabled: false,
  hasSelectedOptions: false,
  count: undefined,
  withoutButton: false,
  noWrap: false,
};

export default FilterDrawerListItem;
