import React from 'react';
import PropTypes from 'prop-types';

import FilterOption from './FilterOption';

const FilterOptionAll = ({ name, type, allText, allCount, selectedFilters, removeSelectedFilters, parentTitle }) => (
  <FilterOption
    key="all"
    id={`${name}-All`}
    count={allCount !== 0 ? allCount : ''}
    type={type}
    value={allText}
    enabled
    selected={!selectedFilters[name]}
    onSelect={(event) => {
      if (event.target.checked) {
        removeSelectedFilters(name);
      }
    }}
    parentTitle={parentTitle}
  />
);

FilterOptionAll.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  allText: PropTypes.string,
  allCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedFilters: PropTypes.shape({}).isRequired,
  removeSelectedFilters: PropTypes.func.isRequired,
  parentTitle: PropTypes.string,
};

FilterOptionAll.defaultProps = {
  allText: 'All',
  allCount: 0,
  parentTitle: '',
};

export default FilterOptionAll;
