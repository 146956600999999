import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const ModelSearch = ({ makesPath, id }) => {
  const [state, setState] = useState({
    makes: [],
    models: [],
  });

  const makeRef = useRef(null);
  const modelRef = useRef(null);

  const getMakes = () => {
    axios
      .get('/car-leasing/bodytypes/specialist/makes.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setState({ ...state, makes: response.data });
      });
  };

  const getModels = (event) => {
    axios
      .get(`/car-leasing/bodytypes/specialist/makes/${event.target.value}/model_ranges.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setState({ ...state, models: response.data });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let path = makesPath;

    if (makeRef.current.value !== '' || modelRef.current.value !== '') {
      path = `/car-leasing/${makeRef.current.value}${modelRef.current.value ? `/${modelRef.current.value}` : ''}`;
    }

    Turbolinks.visit(path);
  };

  useEffect(() => {
    getMakes();
  }, []);

  return (
    <>
      <div className="b-form-group c-search__form-group c-search__form-group--manufacturer">
        <label className="sr-only" htmlFor={`specialist-search-make-${id}`}>
          Manufacturer
        </label>
        <select
          className="c-search__form-control c-search__form-select"
          name={`specialist-search-make-${id}`}
          id={`specialist-search-make-${id}`}
          onChange={(event) => getModels(event)}
          ref={makeRef}
        >
          <option value="">Any make...</option>
          {state.makes.map((make) => (
            <option value={make.slug} key={make.id}>
              {make.name}
            </option>
          ))}
        </select>
      </div>

      <div className="b-form-group c-search__form-group c-search__form-group--model">
        <label className="sr-only" htmlFor={`specialist-search-model-${id}`}>
          Model
        </label>
        <select
          className="c-search__form-control c-search__form-select"
          name={`specialist-search-model-${id}`}
          id={`specialist-search-model-${id}`}
          ref={modelRef}
        >
          <option value="">Any model...</option>
          {state.models.map((model) => (
            <option value={model.slug} key={model.id}>
              {model.name}
            </option>
          ))}
        </select>
      </div>

      <div className="c-search__form-group c-search__form-group--submit">
        <button className="c-search__button" onClick={handleSubmit}>
          Search Cars
        </button>
      </div>
    </>
  );
};

ModelSearch.propTypes = {
  makesPath: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ModelSearch;
