/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Button = ({ type, variant, children, classes, ...args }) => {
  const className = classnames('drv-button', `drv-button--${variant || 'primary'}`, classes);

  return (
    <button className={className} type={type} {...args}>
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'gold', 'white', 'blue', 'electric', 'electric-dark']),
  classes: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  classes: '',
};

export default Button;
