import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Range = ({ data, selected, onChange, labels = data, classes = '', ...args }) => {
  const [value, setvalue] = useState(data.indexOf(selected) + 1);
  const [background, setBackground] = useState('');
  const input = useRef();

  const className = classnames('drv-range', classes);

  useEffect(() => {
    const bgValue = ((value - input.current.min) / (input.current.max - input.current.min)) * 100;
    setBackground(`linear-gradient(to right, #003765 0%, #003765 ${bgValue}%, #d1d1d1 ${bgValue}%, #d1d1d1 100%)`);

    onChange(value);
  }, [value]);

  return (
    <div className={className}>
      <div className="drv-range__input-wrapper">
        <input
          ref={input}
          className="drv-range__input"
          type="range"
          min="1"
          value={value}
          max={data.length}
          step="1"
          data-ui-range-slider-target="input"
          data-action="ui-range-slider#update"
          onChange={(event) => setvalue(event.target.value)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...args}
        />
        <div className="drv-range__bg" style={{ background }} />
        <ul className="drv-range__list">
          {labels.map((label, index) => {
            const isFirst = index === 0;
            const isLast = index === labels.length - 1;
            const labelClassName = classnames('drv-range__option', {
              'drv-range__option--first': isFirst,
              'drv-range__option--last': isLast,
              'drv-range__option--selected': Number(value) === index + 1,
            });

            return (
              <li key={label} className={labelClassName}>
                {(isFirst || isLast) && label}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

Range.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  selected: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  labels: PropTypes.arrayOf(PropTypes.number),
  classes: PropTypes.string,
};

Range.defaultProps = {
  selected: null,
  classes: '',
};

export default Range;
