import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SortOption = ({ name, value, id = `${name}-${value}`, label = value, enabled, type, selected, onSelect }) => {
  return (
    <li className="drv-sort-option">
      <input
        type={type}
        name={name}
        id={id}
        className={classnames('drv-checkbox', {
          'drv-checkbox--radio': type === 'radio',
        })}
        data-action="ga-filters#push"
        data-ga-filters-event-action="sort_applied"
        data-ga-filters-sort-applied={label.toLowerCase().replaceAll(' ', '_')}
        value={value}
        checked={selected}
        disabled={!enabled}
        onChange={onSelect}
      />

      <label htmlFor={id} className="drv-sort-option__label drv-ml-1">
        {label}
      </label>
    </li>
  );
};

SortOption.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  type: PropTypes.string,
};

SortOption.defaultProps = {
  enabled: true,
  type: 'checkbox',
};

export default SortOption;
