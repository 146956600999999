/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

const FilterOption = ({
  name,
  value,
  count,
  id = `${name}-${value}`,
  label = value,
  prelabel,
  enabled = true,
  type = 'checkbox',
  excludeFromForm = false,
  selected,
  onSelect,
  parentTitle,
}) => {
  const dispatch = useDispatch();

  const handleSelect = (event) => {
    onSelect(event);
    dispatch({ type: 'SET_FILTERS_UPDATED', payload: true });
  };

  const safe_id = (idToProcess) => {
    return idToProcess.toLowerCase().replace(/[^a-z0-9]/g, '-');
  };

  return (
    <li className="drv-filter-option">
      <input
        type={type}
        name={excludeFromForm ? null : name}
        id={safe_id(id)}
        className={classnames('drv-checkbox', {
          'drv-checkbox--radio': type === 'radio',
        })}
        data-action="ga-filters#push"
        data-ga-filters-parent-title={parentTitle.toLowerCase().replaceAll(' ', '_')}
        data-ga-filters-event-action={selected ? 'selected_filter_option' : 'deselected_filter_option'}
        data-ga-filters-filter-option={value}
        value={value}
        checked={selected}
        disabled={!enabled}
        onChange={(e) => handleSelect(e)}
      />

      <label
        htmlFor={safe_id(id)}
        className={classnames('drv-filter-option__label drv-ml-1', {
          'drv-filter-option__label--disabled': !enabled,
        })}
      >
        {prelabel && <span className="drv-filter-option__pre-label">{prelabel}</span>} {label}{' '}
        {(count || count === 0) && (
          <span className="drv-filter-option__post-label drv-ml-2">({count.toLocaleString('en-GB')})</span>
        )}
      </label>
    </li>
  );
};

FilterOption.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]),
  prelabel: PropTypes.string,
  enabled: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  excludeFromForm: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  parentTitle: PropTypes.string.isRequired,
};

export default FilterOption;
