import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const FilterDrawerSelectItem = ({ title, disabled, children }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleOutsideClicks = (event) => {
    if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClicks);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClicks);
    };
  }, [dropdownOpen]);

  const handleToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="multiselect" ref={dropdownRef}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className="selectBox" onClick={handleToggle} role="button" tabIndex={0}>
        <select disabled={disabled}>
          <option>{title}</option>
        </select>
        <div className="overSelect" />
      </div>
      <div className="filter-inner" style={{ display: dropdownOpen && !disabled ? 'block' : 'none' }}>
        <ul className="filter-checkboxes">{children}</ul>
        <button className="drv-button drv-button--gold" type="button">
          View XX results
        </button>
      </div>
    </div>
  );
};

FilterDrawerSelectItem.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FilterDrawerSelectItem.defaultProps = {
  disabled: false,
};

export default FilterDrawerSelectItem;
