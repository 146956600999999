import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ToggleCheckbox = ({
  id,
  name,
  value,
  setSingleCheckboxFilter,
  label,
  labelPosition,
  selectedFilters,
  greenToggle,
  isDesktopVersion,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const onChange = (e) => {
    e.persist();
    setSingleCheckboxFilter(e);
    setTimeout(() => {
      e.target.closest('form').dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }, 1);
  };

  if (!id) id = (Math.random() + 1).toString(36).substring(2);

  const selected = (selectedFilters[name] || []).indexOf(value) > -1 || selectedFilters[name] === value;

  const updateCheckboxDisabledState = () => {
    const windowWidth = window.innerWidth;
    const breakpoint = 1200;
    const isMobileView = windowWidth < breakpoint;

    setIsDisabled(isMobileView ? isDesktopVersion : !isDesktopVersion);
  };

  useEffect(() => {
    const handleResize = () => {
      updateCheckboxDisabledState();
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isDesktopVersion]);

  return (
    <div className="c-checkbox__slider">
      {labelPosition === 'left' && (
        <label className="c-checkbox__slider--label" htmlFor={id}>
          {label}
        </label>
      )}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="c-checkbox__slider--switch">
        <input
          style={{ appearance: 'auto' }}
          type="checkbox"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          checked={selected}
          disabled={isDisabled}
        />
        <span className={`c-checkbox__slider--slide ${greenToggle && 'c-checkbox__slider--slide-green'}`} />
      </label>
      {labelPosition !== 'left' && (
        <label className="c-checkbox__slider--label" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

ToggleCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setSingleCheckboxFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  labelPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  isDesktopVersion: PropTypes.bool,
  greenToggle: PropTypes.bool,
};

ToggleCheckbox.defaultProps = {
  id: null,
  greenToggle: false,
  isDesktopVersion: true,
};

export default ToggleCheckbox;
