import { scrollSnapToNext, getScrollSnapPositions } from 'scroll-snap-api';

export class ScrollSnapSlider {
  constructor(slider, leftArrow, rightArrow) {
    this.slider = slider;
    this.leftArrow = leftArrow;
    this.rightArrow = rightArrow;

    this.addEventListeners();
  }

  addEventListeners() {
    this.slider.scrollTo(0, 0);
    this.slider.addEventListener('scroll', this.updateArrows.bind(this));

    this.leftArrow.addEventListener('click', this.arrowOnClick.bind(this, 'left'));
    this.rightArrow.addEventListener('click', this.arrowOnClick.bind(this, 'right'));
  }

  updateArrows() {
    const positions = getScrollSnapPositions(this.slider);

    const [positionsLeft, positionsRight] = this.getCoordinates(positions.x, this.slider.scrollLeft, 2);

    this.toggleArrows(this.leftArrow, positionsLeft.length > 0);
    this.toggleArrows(this.rightArrow, positionsRight.length > 0);
  }

  toggleArrows(arrow, show) {
    if (arrow) {
      arrow.classList.toggle('disabled', !show);
    }
  }

  arrowOnClick(direction) {
    scrollSnapToNext(this.slider, direction);
  }

  getCoordinates(items, midpoint, threshold) {
    const lesser = [];
    const greater = [];
    items.forEach((item) => {
      if (Math.abs(item - midpoint) <= threshold) {
        return;
      }
      if (item < midpoint) {
        lesser.push(item);
      }
      if (item > midpoint) {
        greater.push(item);
      }
    });
    return [lesser, greater];
  }
}
